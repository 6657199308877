import styled from "styled-components"

export const Container = styled.div`
  max-width: 100%;
  width: 78rem;
  margin-left: auto;
  margin-right: auto;
`

export const Box = styled.div`
  padding: 1.5rem;

  @media screen and (max-width: 32rem) {
    padding: 1.5rem 1rem;
  }
`

export const BoxContainer = styled(Container)`
  padding: 1.5rem;

  @media screen and (max-width: 32rem) {
    padding: 1.5rem 1rem;
  }
`

export const Section = styled.section`
  padding: 2rem 0;

  @media screen and (min-width: 56rem) {
    padding: 5rem 0;
  }
`

export const Content = styled.div`
  & > p:not(:empty) {
    margin-bottom: 1rem;
  }

  & > *:last-child {
    margin-bottom: 0;
  }

  p,
  li {
    line-height: 1.75;
  }
`

export enum ButtonLevel {
  primary = "primary",
  secondary = "secondary",
  tertiary = "tertiary",
}

export const Button = styled.button<{ level: ButtonLevel | undefined }>`
  background: red;
  color: green;
  border: 0;
  padding: 0.75rem 1rem;
  display: inline-flex;
  -webkit-appearance: none;
  justify-content: center;
  align-items: center;
  font-weight: 500;
  text-decoration: none;
  cursor: pointer;
  border-radius: ${props => props.theme.borderRadius};
  background-color: ${props =>
    props.level === "primary"
      ? props.theme.colors.primary
      : props.level === "secondary"
      ? props.theme.colors.secondary
      : props.level === "tertiary"
      ? props.theme.colors.tertiary
      : "transparent"};
  color: ${props =>
    props.level === "primary"
      ? "#FFFFFF"
      : props.level === "secondary"
      ? props.theme.colors.white
      : props.level === "tertiary"
      ? "#0F0F0F"
      : props.theme.colors.text};

  &:focus {
    outline: 0;
  }

  & > * {
    margin: 0 0.25rem;
  }
`

export const ButtonLink = styled.a<{ level: ButtonLevel | undefined }>`
  background: red;
  color: green;
  border: 0;
  padding: 0.75rem 1rem;
  display: inline-flex;
  -webkit-appearance: none;
  justify-content: center;
  align-items: center;
  font-weight: 500;
  text-decoration: none;
  cursor: pointer;
  border-radius: ${props => props.theme.borderRadius};
  background-color: ${props =>
    props.level === "primary"
      ? props.theme.colors.primary
      : props.level === "secondary"
      ? props.theme.colors.secondary
      : props.level === "tertiary"
      ? props.theme.colors.tertiary
      : "transparent"};
  color: ${props =>
    props.level === "primary"
      ? "#FFFFFF"
      : props.level === "secondary"
      ? props.theme.colors.white
      : props.level === "tertiary"
      ? "#0F0F0F"
      : props.theme.colors.text};

  &:focus {
    outline: 0;
  }

  & > * {
    margin: 0 0.25rem;
  }
`

export const Picture = styled.picture`
  background-color: #141414;
  display: block;
  position: relative;

  &:after {
    content: '';
    width: 100%;
    height: 100%;
    background-image: url('${require("../images/dark-border.png")}');
    opacity: ${props => (props.theme.theme === "dark" ? 1 : 0)};
    left: 0;
    top: 0;
    background-size: contain;
    background-position: center bottom;
    z-index: 2;
    position: absolute;
    background-repeat: no-repeat;
    pointer-events: none;
  }

  &:before {
    content: '';
    width: 100%;
    height: 100%;
    opacity: ${props => (props.theme.theme === "light" ? 1 : 0)};
    background-image: url('${require("../images/light-border.png")}');
    left: 0;
    top: 0;
    background-size: contain;
    background-position: center bottom;
    z-index: 2;
    position: absolute;
    background-repeat: no-repeat;
    pointer-events: none;
  }

  > * {
    opacity: ${props => props.theme.headerImageOpacity};
    width: 100%;
    min-height: 25rem;
  }
`

export const Intro = styled(BoxContainer)`
  display: grid;
  grid-template-columns: 4fr 6fr;
  gap: 4rem;

  & > *:first-child {
    display: flex;

    & > div {
      margin-top: -40px;
      margin-right: 20px;
      margin-left: -20px;
      flex-shrink: 0;
      transform: rotate(7deg);
      transform-origin: right top;
    }
  }

  @media screen and (max-width: 56rem) {
    grid-template-columns: 1fr;
    gap: 1rem;

    & > *:first-child > div {
      display: none !important;
    }
  }

  h2 {
    font-size: calc(2rem + 1.5vw);
    text-transform: uppercase;
    line-height: 1;
    font-family: ${props => props.theme.fonts.heading};
    margin-top: 0.175rem;
  }
`
