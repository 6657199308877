import React from "react"
import { graphql, useStaticQuery } from "gatsby"

export const Logo: React.FC = () => {
  const data = useStaticQuery(graphql`
    query {
      file(relativePath: { eq: "restaurant-wollegras-logo.png" }) {
        childImageSharp {
          fixed(height: 88, width: 213) {
            src
            srcSet
          }
        }
      }
    }
  `)
  return (
    <img
      css={`
        max-height: 10vh;
      `}
      alt=""
      src={data.file.childImageSharp.fixed.src}
      srcSet={data.file.childImageSharp.fixed.srcSet}
    />
  )
}

export default Logo
