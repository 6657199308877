import React, { CSSProperties } from "react"
import { Button } from "./primitives"
import Icons from "./icons"

type Props = {
  onSwitch: Function
  theme: string
  style?: CSSProperties
}

export const ThemeSwitcher: React.FC<Props> = ({ onSwitch, theme, style }) => {
  return (
    <Button
      style={style}
      onClick={() => onSwitch(theme === "light" ? "dark" : "light")}
    >
      <Icons.SunAndMoon theme={theme} />
    </Button>
  )
}

export default ThemeSwitcher
