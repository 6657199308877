import React from "react"
import { Link } from "gatsby"
import styled, {
  createGlobalStyle,
  ThemeProvider,
  DefaultTheme,
} from "styled-components"
import { Helmet } from "react-helmet"
import Reservation from "../components/reservation"
import ThemeSwitcher from "../components/themeSwitcher"
import Logo from "../components/logo"
import Navigation from "../components/navigation"
import Footer from "../components/footer"
import { Button, ButtonLink, ButtonLevel } from "../components/primitives"
import { useWindowSize } from "../useWindowSize"
import Icons from "../components/icons"

const defaultTheme = {
  borderRadius: "3px",
  fonts: {
    body: `-apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Helvetica, Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol"`,
    heading: `Bebas Neue, sans-serif`,
  },
}

const darkTheme: DefaultTheme = {
  ...defaultTheme,
  theme: "dark",
  imageOpacity: 0.6,
  headerImageOpacity: 0.3,
  colors: {
    white: "#D0D0D0",
    background: "#282828",
    sectionBackground: "#141414",
    inputBackground: "#4B4B4B",
    inputBorder: "transparent",
    heading: "#D0D0D0",
    // border: "#B9B9B9",
    border: "#424242",
    primary: "#56794c",
    text: "#BFBFBF",
    secondary: "#4B4B4B",
    tertiary: "#D0D0D0",
    label: "#555555",
  },
}

const lightTheme: DefaultTheme = {
  ...defaultTheme,
  theme: "light",
  imageOpacity: 1,
  headerImageOpacity: 0.65,
  colors: {
    white: "#FFFFFF",
    background: "#FFFFFF",
    heading: "#141414",
    text: "#0F0F0F",
    sectionBackground: "#F3F4F4",
    inputBackground: "#FFFFFF",
    inputBorder: "#E2E2E2",
    border: "#D8D8D8 ",
    primary: "#56794c",
    secondary: "#191919",
    tertiary: "#FFFFFF",
    label: "#767676",
  },
}

const Header = styled.header`
  display: flex;
  align-items: flex-start;
  padding: 1.5rem;
  position: absolute;
  left: 0;
  width: 100%;
  top: 0;
  z-index: 2;

  & > * + * {
    margin-left: 0.5rem;
  }
`
const Layout: React.FC = ({ children }) => {
  const [currentTheme, setCurrentTheme] = React.useState<"dark" | "light">(
    "light"
  )
  const [reservationDrawerActive, setReservationDrawerActive] = React.useState<
    boolean
  >(false)
  const [menuActive, setMenuActive] = React.useState<boolean>(false)

  const windowSize = useWindowSize()

  const theme = currentTheme === "light" ? lightTheme : darkTheme

  return (
    <>
      <ThemeProvider theme={theme}>
        <GlobalStyle />
        {windowSize.width && windowSize.width <= 1408 && (
          <>
            <Header>
              <Link to="/">
                <Logo />
              </Link>
            </Header>
            <ButtonLink
              css={`
                position: absolute;
                right: 5rem;
                top: 1.5rem;
                width: 3rem;
                height: 3rem;
                padding: 0;
                z-index: 999;
                border-radius: 999rem;

                @media screen and (max-width: 32rem) {
                  right: 4.5rem;
                }
              `}
              style={{
                backgroundColor: menuActive ? "#56B44D" : theme.colors.primary,
                position: menuActive ? "fixed" : "absolute",
              }}
              level={ButtonLevel.primary}
              href="tel:0524222815"
            >
              <Icons.Mobile />
            </ButtonLink>
            <Button
              css={`
                position: absolute;
                right: 1.5rem;
                top: 1.5rem;
                width: 3rem;
                height: 3rem;
                padding: 0.75rem;
                z-index: 999;
                border-radius: 999rem;

                @media screen and (max-width: 32rem) {
                  right: 1rem;
                }
              `}
              style={{
                position: menuActive ? "fixed" : "absolute",
              }}
              level={ButtonLevel.tertiary}
              onClick={() => setMenuActive(!menuActive)}
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="23.594"
                height="15.8"
                viewBox="0 0 23.594 15.8"
                css={`
                  path {
                    transition: 0.15s ease;

                    &:nth-child(2) {
                      transition-delay: 0.075s;
                    }
                  }
                `}
              >
                <g
                  fill="none"
                  stroke="currentColor"
                  stroke-linecap="round"
                  stroke-width="1.8"
                >
                  <path d="M.9.9h21.794" />
                  <path
                    d="M.9 7.9h21.794"
                    transform={`scale(${menuActive ? 0.5 : 1}, 1)`}
                  />
                  <path
                    d="M.9 14.9h21.794"
                    transform={`scale(${menuActive ? 0.5 : 1}, 1)`}
                  />
                </g>
              </svg>
            </Button>
            <div
              css={`
                position: fixed;
                left: 0;
                top: 0;
                width: 100%;
                height: 100%;
                touch-action: none;
                transition: transform 0.3s ease;
                display: flex;
                pointer-events: none;
                /* @ts-ignore */
                background: ${(props) =>
                  props.theme.theme === "light"
                    ? props.theme.colors.primary
                    : props.theme.colors.background};
                z-index: 998;
              `}
              style={{
                pointerEvents: menuActive ? "initial" : "none",
                transform: menuActive ? "translateX(0vw)" : "translateX(100vw)",
                transitionDelay: menuActive ? "0.15s" : "0s",
                transitionTimingFunction: menuActive
                  ? "cubic-bezier(0.37, 0, 0.01, 1.01)"
                  : "cubic-bezier(0.93,-0.02, 0.49, 0.96)",
              }}
            >
              <Navigation onNavigate={() => setMenuActive(false)}>

              </Navigation>
              <ThemeSwitcher
                theme={currentTheme}
                onSwitch={setCurrentTheme}
                style={{ position: "fixed", left: "1.5rem", top: "1.5rem" }}
              />
            </div>
          </>
        )}
        {windowSize.width && windowSize.width > 1408 && (
          <Header>
            <Link to="/">
              <Logo />
            </Link>
            <Navigation />
            <ButtonLink level={ButtonLevel.tertiary} href="tel:0524222815">
              <Icons.Mobile
                style={{ marginRight: "0.5rem", marginLeft: "0rem" }}
              />
              0524 222 815
            </ButtonLink>
            <ThemeSwitcher theme={currentTheme} onSwitch={setCurrentTheme} />
          </Header>
        )}
        {children}
        <Footer />
      </ThemeProvider>
    </>
  )
}

const GlobalStyle = createGlobalStyle`
/* Box sizing rules */
*,
*::before,
*::after {
  box-sizing: border-box;
}

/* Antialiased text with CSS */
* {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

/* Remove default padding */
ul[class],
ol[class] {
  padding: 0;
}

/* Remove default margin */
body,
h1,
h2,
h3,
h4,
p,
ul[class],
ol[class],
li,
figure,
figcaption,
blockquote,
dl,
dd {
  margin: 0;
}

/* Set core body defaults */
body {
  min-height: 100vh;
  scroll-behavior: smooth;
  text-rendering: optimizeSpeed;
  line-height: 1.5;
  background: ${(props) => props.theme.colors.background};
  font-family: ${(props) => props.theme.fonts.body};
  color: ${(props) => props.theme.colors.text};
}

/* Remove list styles on ul, ol elements with a class attribute */
ul[class],
ol[class] {
  list-style: none;
}

/* A elements that don't have a class get default styles */
/* a:not([class]) {
  text-decoration-skip-ink: auto;
} */

/* Make images easier to work with */
img {
  max-width: 100%;
  display: block;
}

/* Inherit fonts for inputs and buttons */
input,
button,
textarea,
select {
  font: inherit;
}

/* Remove all animations and transitions for people that prefer not to see them */
@media (prefers-reduced-motion: reduce) {
  * {
    animation-duration: 0.01ms !important;
    animation-iteration-count: 1 !important;
    transition-duration: 0.01ms !important;
    scroll-behavior: auto !important;
  }
}
`

export default Layout
