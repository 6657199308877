import React, { CSSProperties } from "react"
import { useTheme } from "styled-components"

type Props = {
  style?: CSSProperties
}

const Mobile: React.FC<Props> = ({ style }) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="12.139"
    height="19.422"
    style={style}
  >
    <path
      d="M7.435 16.994H4.7a.457.457 0 01-.455-.455v-.3a.457.457 0 01.455-.459h2.735a.457.457 0 01.455.455v.3a.457.457 0 01-.455.459zm4.7-15.173V17.6a1.821 1.821 0 01-1.821 1.821h-8.5A1.821 1.821 0 010 17.6V1.821A1.821 1.821 0 011.821 0h8.5a1.821 1.821 0 011.818 1.821zm-1.214 0a.609.609 0 00-.607-.607h-8.5a.609.609 0 00-.607.607V17.6a.609.609 0 00.607.607h8.5a.609.609 0 00.607-.607z"
      fill="currentColor"
    />
  </svg>
)

const Menu: React.FC<Props & { active?: boolean }> = ({ active, style }) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="23.594"
    height="15.8"
    style={style}
  >
    <g
      fill="none"
      stroke="currentColor"
      stroke-linecap="round"
      stroke-width="1.8"
    >
      <path d="M.9.9h21.794" transform={active ? "scaleX(0.5)" : "scaleX(1)"} />
      <path d="M.9 7.9h21.794" />
      <path d="M.9 14.9h21.794" />
    </g>
  </svg>
)

const SunAndMoon: React.FC<Props> = ({ style }) => {
  const theme = useTheme()

  if (theme.theme === "dark") {
    return (
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="24.5"
        height="24.495"
        style={style}
      >
        <g data-name="Group 14" fill={theme.colors.white}>
          <path
            data-name="Path 12"
            d="M12.25 4.725a7.61 7.61 0 107.61 7.61 7.62 7.62 0 00-7.61-7.61zm0 13.175a5.57 5.57 0 115.57-5.57 5.578 5.578 0 01-5.57 5.57z"
          />
          <path
            data-name="Path 13"
            d="M12.25 3.19a1.018 1.018 0 001.02-1.02V1.02a1.02 1.02 0 10-2.04 0v1.15a1.018 1.018 0 001.02 1.02z"
          />
          <path
            data-name="Path 14"
            d="M12.25 21.31a1.018 1.018 0 00-1.02 1.02v1.145a1.02 1.02 0 102.04 0V22.33a1.021 1.021 0 00-1.02-1.02z"
          />
          <path
            data-name="Path 15"
            d="M23.48 11.23h-1.15a1.02 1.02 0 000 2.04h1.15a1.02 1.02 0 100-2.04z"
          />
          <path
            data-name="Path 16"
            d="M2.17 11.23H1.02a1.02 1.02 0 100 2.04h1.145a1.02 1.02 0 00.005-2.04z"
          />
          <path
            data-name="Path 17"
            d="M19.47 3.59l-.815.81A1.022 1.022 0 0020.1 5.845l.81-.81a1.02 1.02 0 00-1.44-1.445z"
          />
          <path
            data-name="Path 18"
            d="M4.4 18.656l-.81.81a1.022 1.022 0 001.445 1.445l.81-.81A1.022 1.022 0 004.4 18.656z"
          />
          <path
            data-name="Path 19"
            d="M20.1 18.655a1.022 1.022 0 00-1.445 1.445l.81.81a1.022 1.022 0 101.445-1.445z"
          />
          <path
            data-name="Path 20"
            d="M4.4 5.845A1.022 1.022 0 005.845 4.4l-.81-.81A1.022 1.022 0 003.59 5.035z"
          />
        </g>
      </svg>
    )
  }
  if (theme.theme === "light") {
    return (
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="24.5"
        height="24.5"
        style={style}
      >
        <path
          data-name="moon"
          d="M12.722 20.544a8.3 8.3 0 007.613-4.8 6.507 6.507 0 01-2.743.541 6.7 6.7 0 01-6.7-6.7 6.939 6.939 0 013.614-5.984 12.724 12.724 0 00-1.784-.1 8.521 8.521 0 100 17.043zm0 0"
          fill={theme.colors.white}
        />
      </svg>
    )
  }

  return null
}

export const Arrow: React.FC<Props> = ({ style }) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="30.286"
    height="8.377"
    style={style}
  >
    <g fill="none" stroke="currentColor" stroke-width="1.6">
      <path data-name="Path 27" d="M28.855 4.307H0" />
      <path data-name="Path 28" d="M25.531.566l3.623 3.623-3.623 3.623" />
    </g>
  </svg>
)

export const Cart: React.FC<Props> = ({ style }) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="49"
    height="43.011"
    style={style}
  >
    <defs>
      <clipPath id="a">
        <path
          data-name="Rectangle 283"
          transform="translate(-.155 .152)"
          fill="#339829"
          d="M0 0h48v33H0z"
        />
      </clipPath>
    </defs>
    <g
      data-name="Mask Group 9"
      transform="translate(1.155 4.64)"
      clip-path="url(#a)"
    >
      <ellipse
        data-name="Ellipse 1"
        cx="10"
        cy="10.5"
        rx="10"
        ry="10.5"
        transform="translate(18.845 23.152)"
        fill="#339829"
      />
    </g>
    <path
      fill="currentColor"
      data-name="menu"
      d="M39.404 40.909h-1.506v-1.9h1.506a1.051 1.051 0 001.051-1.051 11.3 11.3 0 00-10.227-11.23v-1.821a1.051 1.051 0 00-2.1 0v1.817a11.3 11.3 0 00-10.23 11.23 1.051 1.051 0 001.051 1.051h1.506v1.9h-1.506a1.051 1.051 0 000 2.1h20.455a1.051 1.051 0 000-2.1zm-3.608 0H22.557v-1.9h13.238zM20.06 36.9a9.177 9.177 0 0118.233 0zm-7.5-26.676c-3.088 0-5.508 1.767-5.508 4.022s2.419 4.022 5.508 4.022 5.507-1.767 5.507-4.022-2.422-4.019-5.51-4.019zm0 5.942c-2.007 0-3.405-1.012-3.405-1.92s1.4-1.92 3.405-1.92 3.405 1.012 3.405 1.92-1.401 1.923-3.408 1.923zm2.476 24.74H2.102V7.216h20.909v17.5a1.051 1.051 0 102.1 0V6.165a1.051 1.051 0 00-1.051-1.051h-.492V1.051A1.051 1.051 0 0022.393.007L.927 2.564A1.051 1.051 0 000 3.608V41.96a1.051 1.051 0 001.051 1.051h13.981a1.051 1.051 0 100-2.1zM2.102 4.541l19.364-2.306v2.879H2.102zM10.787 37.9a1.051 1.051 0 010-2.1h3.54a1.051 1.051 0 010 2.1h-3.54z"
    />
  </svg>
)

const Icons = {
  Mobile: Mobile,
  Menu: Menu,
  SunAndMoon: SunAndMoon,
  Arrow: Arrow,
  Cart: Cart,
}

export default Icons
