import React from "react"
import { BoxContainer, ButtonLink, Container } from "./primitives"
import styled from "styled-components"
import { graphql, useStaticQuery } from "gatsby"

const YoYoYoDitIsDeFooter = styled.footer`
  color: #cbcbcb;
  background: #0d0d0d;

  & > div {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(24rem, 1fr));
    gap: 8rem;

    @media screen and (max-width: 28rem) {
      grid-template-columns: 1fr;
      gap: 4rem;
    }
  }

  a {
    color: inherit;
  }
`

const FooterBlock = styled.div`
  & > * + * {
    margin-top: 1.5rem;
  }

  h4 {
    color: ${(props) => props.theme.colors.white};
    font-size: 1.25rem;
    text-transform: uppercase;
    line-height: 1;
    font-family: ${(props) => props.theme.fonts.heading};
  }

  p {
    line-height: 1.75;

    strong {
      font-weight: 400;
      color: #fff;
    }
  }

  & > div {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(14rem, 1fr));

    a {
      text-decoration: none;

      &:hover {
        text-decoration: underline;
      }
    }
  }
`

const Button = styled(ButtonLink)`
  border: 1px solid #3c3c3c;
  color: #cbcbcb;
`

export const Footer: React.FC = () => {
  const query = useStaticQuery(graphql`
    query {
      wp {
        openingstijden {
          openingTimes {
            row {
              days
              time
            }
          }
        }
      }
    }
  `)

  const openingTimes = query.wp?.openingstijden?.openingTimes?.row

  return (
    <>
      <YoYoYoDitIsDeFooter>
        <BoxContainer
          css={`
            padding-top: 6rem;
            padding-bottom: 6rem;
          `}
        >
          <FooterBlock>
            <h4>Restaurant Wollegras</h4>
            <div>
              <p>
                Ambachtsweg 5<br />
                7765BX Weiteveen
              </p>
              <p>
                <a href="tel:0524222815">+31(0)524-222815</a>
                <br />
                <a href="mailto:info@restaurantwollegras.nl">
                  info@restaurantwollegras.nl
                </a>
              </p>
            </div>
            <Button
              href="https://goo.gl/maps/xASGxxyubEtCN7cB7"
              target="_blank"
            >
              Plan je route
            </Button>
          </FooterBlock>
          <FooterBlock>
            <h4 dangerouslySetInnerHTML={{ __html: "Openingstijden" }} />
            <div>
              {openingTimes.length > 0 &&
                openingTimes.map((row: { days: string; time: string }) => (
                  <p>
                    <strong dangerouslySetInnerHTML={{ __html: row.days }} />
                    <br />
                    {row.time}
                  </p>
                ))}
            </div>
          </FooterBlock>
        </BoxContainer>

        <BoxContainer>
          <a href="https://www.webba.nl/" target="_blank">
            Website: Webba
          </a>
        </BoxContainer>
      </YoYoYoDitIsDeFooter>
    </>
  )
}

export default Footer
