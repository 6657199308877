import React from "react"
import styled from "styled-components"
import { Link, graphql, useStaticQuery } from "gatsby"

const Nav = styled.nav`
  display: flex;
  align-items: center;
  margin-left: auto !important;

  > * {
    padding: 0.75rem 1.25rem;
    color: ${(props) => props.theme.colors.white};
    text-decoration: none;
    border-radius: ${(props) => props.theme.borderRadius};
    position: relative;

    @media screen and (min-width: 1408px) {
      &:after {
        content: "";
        position: absolute;
        width: calc(100% - 2.5rem);
        height: 1px;
        left: 1.25rem;
        top: calc(100% - 0.75rem);
        transform: scaleX(0);
        transform-origin: center center;
        background: ${(props) => props.theme.colors.white};
        transition: transform 0.2s ease-in;
      }

      &:hover,
      &.active {
        &:after {
          transform: scaleX(1);
          transition: transform 0.2s ease-out;
        }
      }
    }
  }

  @media screen and (max-width: 1408px) {
    flex-direction: column;
    width: 100%;
    margin-top: 6rem;

    & > * {
      width: 100%;
      padding: 0.25rem 2.5rem;
      text-transform: uppercase;
      font-family: ${(props) => props.theme.fonts.heading};
      font-size: 2rem;
    }
  }
`

export const Navigation: React.FC<{
  onNavigate: Function | undefined
  children: any
}> = ({ onNavigate, children }) => {
  const handleOnClick = () => {
    if (typeof onNavigate === "function") {
      onNavigate()
    }
  }

  const query = useStaticQuery(graphql`
    query {
      wp {
        generalSettings {
          url
        }
        menu(id: "TWVudToy") {
          menuItems {
            nodes {
              id
              label
              url
            }
          }
        }
      }
    }
  `)

  console.log(query)

  const menu = query.wp?.menu?.menuItems?.nodes
  const baseUrl = query.wp?.generalSettings?.url

  const items = menu.map((item) => ({
    ...item,
    url: item.url.replace(baseUrl, ""),
  }))

  return (
    <>
      <Nav>
        {items.map((item) => (
          <Link
            onClick={handleOnClick}
            partiallyActive={item.label === "Home" ? false : true}
            activeClassName="active"
            to={item.url === "" ? "/" : item.url}
            dangerouslySetInnerHTML={{ __html: item.label }}
          />
        ))}
        {children}
      </Nav>
    </>
  )
}

export default Navigation
